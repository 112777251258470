<template>
    <section>
        <div class="row mx-0 py-2 align-items-center">
            <div class="col-auto">
                <div class="btn-back" @click="$router.push({name: 'admin.cupones.clientes.lista'});">
                    <i class="icon-left f-14" />
                </div>
            </div>
            <div class="col pl-0 text-general f-15">
                Crear Cupón
            </div>
        </div>
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 163px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                {
                    titulo: 'Información',
                    ruta: 'admin.cupones.crear.informacion'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
section{
    border-radius: 12px 12px 0px 0px;
}
</style>
